<template>
  <div class="background">
    <div class="progress_center">
      <v-alert :text="text" min-width="240">
        <v-progress-linear
          v-model="percentageVal"
          class="mt-4"
          height="18"
          color="primary-lighten-3"
        >
          {{ Math.ceil(percentageVal) }}%
        </v-progress-linear></v-alert
      >
    </div>
  </div>
</template>
    
<script setup>
const props = defineProps({
  percentage: {
    type: Number,
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
});

let percentageVal = ref(0);

onMounted(() => {
  percentageVal.value = props.percentage;
});

watch(
  () => props.percentage,
  (first, second) => {
    percentageVal.value = first;
  }
);
</script>
  <style lang="scss" scoped>
.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}
.progress_center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
}
</style>
  