<template>
  <div class="toast__container">
    <v-slide-y-reverse-transition tag="v-alert" group>
      <v-alert
        v-for="alert in alerts"
        :border-color="alert.borderColor"
        :type="alert.type"
        :key="alert.id"
        transition="slide-y-transtion"
        class="toast"
        closable
        color="dark-darken-7"
        border="start"
        max-width="480"
        @click:close="hideAlert(alert.id)"
      >
        <template v-slot:prepend>
          <v-icon
            :icon="alert.icon"
            size="x-large"
            :color="alert.iconColor"
          ></v-icon>
        </template>

        <template v-slot:text>
          <div v-if="Array.isArray(alert.message)">
            <div v-for="(item, index) in alert.message" :key="index">
              {{ item }}
            </div>
          </div>
          <div v-else>
            {{ alert.message }}
          </div>
        </template>
      </v-alert>
    </v-slide-y-reverse-transition>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useWarningStore } from "~/store/globalMessage";

const store = useWarningStore();

const alerts = computed(() => store.alerts);

const hideAlert = (id) => {
  store.hideAlert(id);
};

// Observa los cambios en las alertas y establece timeouts si es necesario
watch(
  alerts,
  (newAlerts) => {
    newAlerts.forEach((alert) => {
      if (alert.timeout) {
        setTimeout(() => {
          hideAlert(alert.id);
        }, parseInt(6000));
      }
    });
  },
  { deep: true }
);
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.toast {
  box-shadow: rgb(2 18 37 / 10%) 0px 10px 15px -3px,
    rgb(25 41 76 / 4%) 0px 4px 6px -2px;
  margin-bottom: 10px;

  &__container {
    position: fixed;
    top: 110px;
    right: $space-md;
    display: flex;
    flex-direction: column;
    gap: $space-sm; /* Espacio entre alertas */
    z-index: 2401; /* Inmeditatamente posterior al z-index de la modal de vuetify */
  }
}
</style>
