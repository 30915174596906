<template>
  <div>
    <v-app class="app principal__layout">
      <LayoutsHeader />
      <div style="padding-top: 100px">
        <slot />
      </div>
      <CommonToast v-for="index in 3" :key="index" />
      <CommonModal
        :dialog="locationSelector.visible"
        @cancel-click="locationSelector.setVisible(false)"
        @confirm-click="locationSelector.saveLocations()"
        :confirm="true"
        :title="$t('locationSelector.title')"
        btn-confirm="save"
      >
        <LocationSelector />
      </CommonModal>

      <!-- Botón flotante para scroll hacia arriba -->
      <v-fade-transition>
        <v-btn
          v-show="showScrollButton"
          color="primary"
          elevation="22"
          slim
          density="comfortable"
          class="position-fixed bottom-0 right-0 ma-6"
          @click="scrollToTop"
          icon="mdi-chevron-up"
        >
        </v-btn>
      </v-fade-transition>
    </v-app>
  </div>
</template>

<script setup>
import { useLocationSelector } from "~/store/locationSelector";

const locationSelector = useLocationSelector();

// Variable reactiva para controlar la visibilidad del botón
const showScrollButton = ref(false);

// Función para manejar el scroll de la página
const handleScroll = () => {
  showScrollButton.value = window.scrollY > 1200; // Muestra el botón cuando se ha hecho scroll más de 1200px
};

// Función para hacer scroll hacia arriba
const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

// Añadir el evento de scroll al montar el componente
onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

// Remover el evento de scroll al desmontar el componente
onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>




<style lang="scss">
</style>
